<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>资源</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <!-- 搜索与添加区域 -->
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="querytype">
              <div style="display: inline-block" class="divSpan">类型：</div>
              <div style="display: inline-block">
                <ELselect
                  v-model="queryinfo.querytype"
                  :options="resourceNameOptions"
                  :optionKey="ResourceOptionKeys"
                  Splaceholder="请选择搜索的类型"
                  @clear="getResourceList"
                ></ELselect>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6.5">
            <el-form-item prop="queryname">
              <div style="display: inline-block" class="divSpan">名称：</div>
              <div style="display: inline-block">
                <el-input
                  placeholder="请输入搜索的名称"
                  v-model.trim="queryinfo.queryname"
                  clearable
                  @clear="getResourceList"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="1.5">
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
        </el-col>
        <el-col :span="1">
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :span="3">
          <el-button
            round
            type="primary"
            icon="el-icon-circle-plus"
            @click="addDialog1"
            >添加资源</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ResourceList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="number"
            label="序号"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="uri"
            label="URI"
            sortable
            min-width="150px"
          ></el-table-column>

          <!-- operation 渲染 -->
          <el-table-column label="操作" min-width="250px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="showEditDialog(scope.row)"
                >编辑</el-button
              >

              <el-button
                type="danger"
                size="mini"
                @click="removeResourceById(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="编辑资源信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="序号：" prop="number">
          <el-input v-model.trim="editForm.number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editResourceInfo">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="添加资源信息"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="150px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="addForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="URI：" prop="uri">
          <el-input v-model.trim="addForm.uri" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="type">
          <el-input v-model.trim="addForm.type" clearable></el-input>
        </el-form-item>
        <el-form-item label="父资源：" prop="parent">
          <ELselect
            v-model="addForm.parent"
            :options="ResourceParentList"
            :optionKey="ResourceParentOptionKeys"
            Splaceholder="请选择父资源"
          ></ELselect>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addREsource">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看资源信息"
      :visible.sync="viewDialogVisible"
      width="30%"
      @close="viewDialogClosed"
    >
      <el-form :model="viewForm" ref="viewFormRef" label-width="100px">
        <el-form-item label="ID：">
          <el-input v-model.trim="viewForm.id"></el-input>
        </el-form-item>
        <el-form-item label="名称：">
          <el-input v-model.trim="viewForm.name"></el-input>
        </el-form-item>
        <el-form-item label="序号：">
          <el-input v-model.trim="viewForm.number"></el-input>
        </el-form-item>
        <el-form-item label="父资源：">
          <el-input v-model.trim="viewForm.parent"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-input v-model.trim="viewForm.type"></el-input>
        </el-form-item>
        <el-form-item label="URI：">
          <el-input v-model.trim="viewForm.uri"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ResourcefindPage, deleteResource, getResourceById, updateResource, addResource, CountResourceName, getResources, ResourcefindByName, ResourcefindByType } from '@/api/systemanager/system_resource'
import ELselect from '@/components/myself/el_select'
import { deleteDialog } from '@/libs/confirmDialog'
import { dynamicColumnType } from '@/components/myself/dynamicCol'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        queryname: '',
        querytype: '',
        pageNum: 1,
        pageSize: 8
      },
      total: 0,
      ResourceList: [],
      ResourceOptionKeys: {
        value: 'type',
        label: 'type',
        label2: ''
      },
      resourceNameOptions: [],
      editDialogVisible: false,
      editForm: {
        name: '',
        number: ''
      },
      editFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 3, max: 10, message: '长度必须在3-10个字符间', trigger: 'blur' }
        ]
      },
      addDialogVisible: false,
      addForm: {
        name: '',
        uri: '',
        parent: '',
        type: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 3, max: 10, message: '长度必须在3-10个字符间', trigger: 'blur' }
        ]
      },
      viewDialogVisible: false,
      viewForm: {},
      ResourceParentOptionKeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
      ResourceParentList: []
    }
  },
  mounted () {
    this.getResourceType()
    this.getResourceList()
    this.Parentnames()
  },
  methods: {
    async getResourceList () {
      const res = await ResourcefindPage(this.queryinfo.pageNum, this.queryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.ResourceList = res.data.data.list
      this.total = res.data.data.total
      // 查询
      if (this.queryinfo.queryname !== '' && this.queryinfo.querytype === '') {
        const res = await ResourcefindByName(this.queryinfo.queryname, this.queryinfo.pageNum, this.queryinfo.pageSize)
        if (res.status !== 200) return this.$message.error('获取列表失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.ResourceList = res.data.data.list
        this.total = res.data.data.total
      } else if (this.queryinfo.querytype !== '' && this.queryinfo.queryname === '') {
        const res = await ResourcefindByType(this.queryinfo.querytype, this.queryinfo.pageNum, this.queryinfo.pageSize)
        if (res.status !== 200) return this.$message.error('获取列表失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.ResourceList = res.data.data.list
        this.total = res.data.data.total
      } else if (this.queryinfo.querytype !== '' && this.queryinfo.queryname !== '') {
        const res = await ResourcefindByType(this.queryinfo.querytype, this.queryinfo.pageNum, this.queryinfo.pageSize)
        if (res.status !== 200) return this.$message.error('获取列表失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        res.data.data.list.forEach(item => {
          if (item.name.toLowerCase().includes(this.queryinfo.queryname.toLowerCase())) {
            this.$set(item, 'answer1', 'yes')
          }
        })
        res.data.data.list = res.data.data.list.filter((data) => { return data.answer1 === 'yes' })
        this.ResourceList = res.data.data.list
        this.total = res.data.data.total
      }
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getResourceList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getResourceList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getResourceList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getResourceList()
    },
    async getResourceType () {
      const AllTypes = await getResources()
      if (AllTypes.status !== 200) return this.$message.error('获取列表失败')
      // 去重
      this.resourceNameOptions = dynamicColumnType(AllTypes.data.data)
    },
    async removeResourceById (rowinfo) {
      // 询问用户是否删除数据
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deleteResource(rowinfo.id)
        if (res.status !== 200) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getResourceList()
      }
    },
    async showEditDialog (rowinfo) {
      // 先获取原有数据，再进行修改刷新
      const res = await getResourceById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('修改失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.editForm = res.data.data
      this.editDialogVisible = true
    },
    // 监听修改对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改信息并提交
    editResourceInfo () {
      this.$refs.editFormRef.validate(async valid => {
        // 可以发现修改信息的请求
        if (valid) {
          const resl = await CountResourceName(this.editForm.name)
          if (resl.data.data > 0) return this.$message.error('名称已存在')

          const res = await updateResource(this.editForm, this.editForm.id)
          if (res.status !== 200) return this.$message.error('失败的更新资源信息')
          this.editDialogVisible = false
          this.getResourceList()
          this.$message.success('成功的更新组织信息')
        }
      })
    },
    // 此处是因为parent调用自身数据，每次接口需要手动刷新数据才更新
    addDialog1 () {
      this.addDialogVisible = true
      this.Parentnames()
    },
    addREsource () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          // 此处是模糊查询，后面需要修改
          const resl = await CountResourceName(this.addForm.name)
          if (resl.data.data > 0) return this.$message.error('名称已存在')

          const res = await addResource(this.addForm)
          if (res.status !== 200) {
            this.$message.error('添加资源失败')
          }
          this.$message.success('添加资源成功')
          this.addDialogVisible = false
          this.getResourceList()
        }
      })
    },
    async Parentnames () {
      const parentids = await getResources()
      if (parentids.status !== 200) return this.$message.error('获取列表失败')
      this.ResourceParentList = parentids.data.data.map(item => item)
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    async viewEditDialog (rowinfo) {
      const res = await getResourceById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('查看失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.viewForm = res.data.data
      this.viewDialogVisible = true
    },
    viewDialogClosed () {
      this.$refs.viewFormRef.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
</style>

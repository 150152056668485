import axios from '@/libs/api.request'

export const ResourcefindByName = (name, pageNum, pageSize) => {
  let uri = '/system/resource/name/' + name + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const ResourcefindPage = (pageNum, pageSize) => {
  let uri = '/system/resource/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const ResourcefindByType = (type, pageNum, pageSize) => {
  let uri = '/system/resource/type/' + type + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const addResource = entity => {
  let uri = '/system/resource/'
  return axios.request({
    url: uri,
    method: 'put',
    data: entity
  })
}

export const deleteResource = id => {
  let uri = '/system/resource/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const getResourceById = id => {
  let uri = '/system/resource/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const updateResource = (entity, id) => {
  let uri = '/system/resource/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const CountResourceName = name => {
  let uri = '/system/resource/count/name/' + name
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getResources = () => {
  let uri = '/system/resource/'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 动态获取列

// typeId
export const dynamicColumnTypeId = list => {
  const res = new Map()
  let arr = list.filter(list => !res.has(list.typeId) && res.set(list.typeId, 1))
  return arr
}

// id
export const dynamicColumnId = list => {
  const res = new Map()
  let arr = list.filter(list => !res.has(list.id) && res.set(list.id, 1))
  return arr
}

// type 
export const dynamicColumnType = list => {
  const res = new Map()
  let arr = list.filter(list => !res.has(list.type) && res.set(list.type, 1))
  return arr
}
